/**
 * Set down payment to be 0 IF:
 * Down Payment check box is unchecked.
 * OR down payment is greater than final total cost // This could happen when deleting areas.
 * @param {object} active
 * @param {object} option
 * @returns {void}
 */

export const processDownPayment = (active, option) => {
  // Milan Lenders variables
  const isMyMilanPrimary = active.lender.name === 'myMILAN'; // As a primary lender.  NOT Secondary

  // Note: The minLoanRequired (minimum required financed amount) varies between lenders,
  // To avoid any bugs when switching between lenders: if total financed is less than minLoanRequired:
  const minLoanRequired = active.lender.minLoanRequired[option.financePlan];
  if (
    !option.downPmtEnabled ||
    option.downpayment > option.finalTotalCost ||
    (minLoanRequired > 0 && option.totalFinanced < minLoanRequired)
  ) {
    option.downPmtEnabled = false;
    // myMILAN takes the first monthly payment as a down payment. Therefore,
    // option.downpayment should never be 0 for myMILAN
    option.downpayment = isMyMilanPrimary ? option.requiredDownPmt : 0;
  }
};

// ------- Use this if myMILAN is deprecated ------- //
// export const processDownPayment = (active, option) => {
//   // Note: The minLoanRequired (minimum required financed amount) varies between lenders,
//   // To avoid any bugs when switching between lenders: if total financed is less than minLoanRequired:
//   const minLoanRequired = active.lender.minLoanRequired[option.financePlan];
//   if (
//     !option.downPmtEnabled ||
//     option.downpayment > option.finalTotalCost ||
//     (minLoanRequired > 0 && option.totalFinanced < minLoanRequired)
//   ) {
//     option.downPmtEnabled = false;
//     option.downpayment = 0;
//   }
// };
