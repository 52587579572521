import React from 'react'
import OptionTile from './OptionTile'
import ItemsTable from './ItemsTable'

function ForManagers({ options }) {
  return (
    <div className="row gy-3 flex-wrap pt-5 pb-4">
      {
        Object.keys(options).map(key => (
          <div key={key} className="col-md-4">
            <OptionTile optionKey={key} Child={ItemsTable} />
          </div>
        ))
      }
    </div>
  )
}

export default ForManagers