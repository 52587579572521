import React from 'react';
import CustomCheckbox from './CustomCheckbox';

function DownSplitCheckBoxes({
  active,
  option,
  optionKey,
  setOptions,
  splitFinanceFeature,
}) {
  const handleCheckBox = (type) => {
    switch (type) {
      case 'downpayment':
        setOptions((prev) => ({
          ...prev,
          [optionKey]: {
            ...option,
            downPmtEnabled: !option.downPmtEnabled,
          },
        }));
        break;
      case 'split_loan':
        setOptions((prev) => ({
          ...prev,
          [optionKey]: {
            ...option,
            splitLoan: {
              ...option.splitLoan,
              isEnabled: !option.splitLoan.isEnabled,
            },
          },
        }));
        break;
      default:
        return;
    }
  };

  return (
    <div className='container-fluid milan-bg-secondary print-hide'>
      <div className='row gx-1'>
        <div className='col-6'>
          <div className='custom-checkbox py-1 text-start'>
            <CustomCheckbox
              id='downpayment'
              label='Down Payment'
              handleCheckBox={handleCheckBox}
              isChecked={option.downPmtEnabled}
            />
          </div>
        </div>
        <div className='col-6 custom-checkbox'>
          {splitFinanceFeature && active.lender.canSplit && (
            <div className='custom-checkbox py-1 pe-2 text-end'>
              <CustomCheckbox
                id='split_loan'
                label='Split Financing'
                handleCheckBox={handleCheckBox}
                isChecked={option.splitLoan.isEnabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownSplitCheckBoxes;
