import React from 'react';
import Table from 'react-bootstrap/Table';
import { USCurrentStyleDecimal } from '../../../utils';
// import { pifDiscount } from '../../../constants'

function ItemsTable({ option }) {
  return (
    <Table className='mb-0'>
      <thead className='bg-light'>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Items</th>
          <th scope='col'>Cost</th>
          {/* {option?.pifSaving > 0 && <th scope="col">Pif</th>} // Deprecated */}
        </tr>
      </thead>
      <tbody>
        {option.items.map((item, x) => (
          <tr key={x} className=''>
            <th scope='row'>{x + 1}</th>
            <td>{item.name}</td>
            <td>{USCurrentStyleDecimal(item.finalCost)}</td>
            {/* {option?.pifSaving > 0 && <td>{USCurrentStyleDecimal(item.finalCost * (1 - pifDiscount))}</td>} // Deprecated */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ItemsTable;
