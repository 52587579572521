import { calculateInstallment, calcInstallmentWithDown } from '.';

// ---------------------  $$$$ Split Financing $$$$ ------------------- //
// -----------------  $$$$ Secondary Lender Logic $$$$ ------------------- //
export const handleSecondaryLender = (
  active,
  option,
  primaryLender,
  secondaryLender,
  accurateFinalTotalCost
) => {
  const primaryRequiredDownPmt = primaryLender.requiredDownPmt; // Crucial // Do NOT remove this line

  // Secondary Lender is either myMILAN
  // By default, a secondary lender requires a down payment equal to the first month payment
  // Unlike other lenders, this required down payment (today's payment) must be included in the total financed amount
  // Therefore, this line will overwrite this line: (option.totalFinanced = option.finalTotalCost - option.todaysPayment) above
  option.totalFinanced = accurateFinalTotalCost - primaryRequiredDownPmt; // Crucial // Do NOT remove this line

  const myMilanLender = active.state.lenders.find(
    (lender) => lender.name === 'myMILAN'
  );

  // Secondary Lender Financed Amount
  secondaryLender.financedAmount =
    option.totalFinanced - primaryLender.financedAmount;
  // Required Down Payment is basically the monthly payment for the secondary lender
  secondaryLender.requiredDownPmt = calculateInstallment(
    secondaryLender.financedAmount,
    myMilanLender
  ); // returns an array
  // Check if the entered down payment is smaller than the required one
  const isDownPmntSmallerThanRequired =
    option.downpayment - primaryRequiredDownPmt <=
    secondaryLender.requiredDownPmt;
  // The today's payment must be equal to or greater than the required down payment
  option.todaysPayment = isDownPmntSmallerThanRequired
    ? primaryRequiredDownPmt + secondaryLender.requiredDownPmt
    : option.downpayment;

  if (isDownPmntSmallerThanRequired) {
    // In this case, the monthly payment for the secondary lender is equal to the required down payment
    secondaryLender.monthlyPayment = [secondaryLender.requiredDownPmt]; // must be an array
  } else {
    // if the entered down payment is greater than the required one
    const remainingDownPmt = option.todaysPayment - primaryRequiredDownPmt;
    const financed = secondaryLender.financedAmount;
    secondaryLender.monthlyPayment = [
      calcInstallmentWithDown(financed, remainingDownPmt, myMilanLender),
    ]; // must be an array
    // Update Secondary Lender Required Down Payment
    secondaryLender.requiredDownPmt = secondaryLender.monthlyPayment[0];
    // Update Secondary Lender Financed Amount
    secondaryLender.financedAmount -=
      option.downpayment - option.requiredDownPmt;
  }
  // Update Option Required Down Payment
  option.requiredDownPmt =
    primaryRequiredDownPmt + secondaryLender.requiredDownPmt;
};
