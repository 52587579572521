import React, { useEffect, useState, useContext } from 'react'
import { QuoteContext } from '..'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AreasList from './AreasList'

export function AreasContainer({ parentsAreasArr, clearOptionsClicked }) {
  const { options, active } = useContext(QuoteContext)
  // Not using parentsAreasArr directly because it doesn't get updated when areas get selected or un-selected
  const [parentsAreas, setParentsAreas] = useState(parentsAreasArr)
  const LOWER_HALF_AREAS = parentsAreasArr.filter(parentArea => parentArea.body_half === 'lower').map(parentArea => parentArea.areas).flat()
  const UPPER_HALF_AREAS = parentsAreasArr.filter(parentArea => parentArea.body_half === 'upper').map(parentArea => parentArea.areas).flat()

  const [activeKey, setActiveKey] = useState(0)

  const ACTIVE_OPTION_ITEMS = options[active.optionKey].items
  useEffect(() => {
    // This will get the areas updated / Usefull to check for the "selected" property
    const parentsAreasClone = [...parentsAreas]
    setParentsAreas(parentsAreasClone)
  }, [active.optionKey, ACTIVE_OPTION_ITEMS])

  useEffect(() => {
    if (clearOptionsClicked && activeKey !== 0) setActiveKey(0)
  }, [clearOptionsClicked])

  return (
    <section className="tabs-container px-0">
      <Tabs
        id="body-areas-tabs"
        activeKey={activeKey}
        transition={false}
        onSelect={(k) => setActiveKey(k)}
        className={`body-areas-tabs ${active.preSale ? 'primary-bg' : ''} justify-content-start padding-x flex-nowrap pe-0`}
      >
        {
          parentsAreas.map(parentArea => (
            <Tab key={parentArea.index} eventKey={parentArea.index} title={parentArea.name}>
              <AreasList
                parentArea={parentArea}
                bodyHalf={parentArea.name === 'Upgrade: Lower' ? LOWER_HALF_AREAS : parentArea.name === 'Upgrade: Upper' ? UPPER_HALF_AREAS : []}
              />
            </Tab>
          ))
        }
      </Tabs>
    </section>
  )
}

export default AreasContainer