import React, { useContext } from 'react'
import { USCurrentStyleDecimal } from '../../../utils'
import { QuoteContext } from '../../QuoteManager'

function OptionTile({ optionKey, Child }) {
  const { options, active } = useContext(QuoteContext)
  const option = options[optionKey]
  if(option.items.length === 0) return null

  return (
    <div className={`card h-100 card-border ${active.preSale ? 'primary-border' : ''}`}>
      <div className={`card-header ${active.preSale ? 'milan-bg-primary-light' : 'milan-bg-light-tur'}`}>
        <strong className="milan-text-primary">Option {optionKey}</strong>
        {option.fee > 0 && <span className="float-end milan-text-primary">Fee: {USCurrentStyleDecimal(option.fee)}</span>}
      </div>
      {option.items.length > 0 && <Child option={option} />}
    </div>
  )
}

export default OptionTile