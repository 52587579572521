import React from 'react';
import MilanLogoBlue from '../assets/Milan-Logo-Blue.webp';
import { SelectLender } from '../constants';

export function PrintHeader({ active }) {
  return (
    <section className='container-fluid pt-4 print-display'>
      <div className='row justify-content-between align-items-center'>
        <div className='col-auto'>
          <h1 className='milan-text-tur mb-0 h4'>Custom Quote(s)</h1>
        </div>
        <div className='col-auto'>
          <figure className='text-center mb-0'>
            <img
              src={MilanLogoBlue}
              alt='Milan Logo Blue'
              style={{ maxWidth: '140px' }}
            />
          </figure>
        </div>
      </div>
      <div className='row gx-1 mt-3'>
        {/* <div className='col-auto'>
          <p>
            <strong>Financing:</strong> {active.financePlan} Month
          </p>
        </div> */}
        {active.lender.name !== SelectLender.name && (
          <div className='col-auto'>
            <p>
              <strong>Lender:</strong> {active.lender.name}
            </p>
          </div>
        )}
        {active.fee.id !== 'no_fees' && (
          <div className='col-auto'>
            <p>
              | <strong>Fee:</strong> %{active.fee.percentage * 100}
            </p>
          </div>
        )}
        {active.privateSale !== 'None' && (
          <div className='col-auto'>
            <p>
              | <strong>Private Sale:</strong> ${active.privateSale.slice(2)}
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default PrintHeader;
