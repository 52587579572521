import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: 'milan-qc.firebaseapp.com',
  projectId: 'milan-qc',
  storageBucket: 'milan-qc.appspot.com',
  messagingSenderId: '2267146804',
  appId: '1:2267146804:web:45884eb66a41d3b7aef861',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
