import React, { useEffect, useState, useRef } from 'react';
import { USCurrentStyle } from '../../../utils';

const USNumberFormat = (value) => {
  const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
  return numericValue !== 0 ? USCurrentStyle(numericValue) : '';
};

const ApprovedAmountInput = ({ option, active, setOptions }) => {
  const inputRef = useRef(null);
  const { optionKey } = active;
  const { approvedAmount } = option.splitLoan.primaryLender;
  const optionFinancePlan = option.financePlan; // Do NOT use active.financePlan
  const minLoanRequired = active.lender.minLoanRequired[optionFinancePlan];
  const { totalFinanced } = option;
  const [value, setValue] = useState(
    approvedAmount > 0 ? USCurrentStyle(approvedAmount) : ''
  );
  const [error, setError] = useState(null);
  const [revalidate, setRevalidate] = useState(false);

  const handleInputChange = (e) => {
    setError(null);
    if (error !== null) setError(null);
    let amount = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (amount > totalFinanced) {
      setError(`Amount cannot exceed ${USCurrentStyle(totalFinanced)}`);
      setTimeout(() => {
        // Don't pass setError(null) here
        setRevalidate(!revalidate); // Just in case the previously entered amount is still less than the min requirement
      }, 3000);
      return;
    }
    // Format the value as currency
    const formattedValue = USNumberFormat(amount);

    setValue(formattedValue);
    setOptions((prev) => ({
      ...prev,
      [optionKey]: {
        ...option,
        splitLoan: {
          ...option.splitLoan,
          primaryLender: {
            ...option.splitLoan.primaryLender,
            approvedAmount: +amount,
          },
        },
      },
    }));
  };

  // When deleting an area, the approved amount can be bigger than the total cost,
  // Therefore, the approvedAmount defaults to 0 in the updateOptionProps.js in that case
  useEffect(() => {
    setError(null);
    if (approvedAmount === 0) setValue('');
    if (
      minLoanRequired &&
      approvedAmount !== 0 &&
      approvedAmount < minLoanRequired
    ) {
      setError(
        `Amount must be at least ${USCurrentStyle(
          minLoanRequired
        )} for the ${optionFinancePlan} month plan`
      );
    } else if (approvedAmount > totalFinanced) {
      setError(`Amount cannot exceed total financed`);
    } else {
      setError(null);
    }
  }, [
    approvedAmount,
    minLoanRequired,
    optionFinancePlan,
    totalFinanced,
    revalidate,
  ]);

  useEffect(() => {
    // Focus the input element when the component mounts
    inputRef.current.focus();
  }, []);

  return (
    <div className='row gy-2 pb-3 align-items-center print-hide'>
      <div className='col-xxl-5'>
        <p className='mb-0 lh-1'>
          <small>{active.lender.name}</small>
        </p>
      </div>
      <div className='col-xxl-7'>
        <div>
          <input
            ref={inputRef}
            type='text'
            value={value}
            onChange={handleInputChange}
            className={`form-control currency-input text-center mx-auto ${
              error ? 'border-danger milan-text-red' : ''
            }`}
            placeholder='$0,000'
          />
        </div>
      </div>
      {error && (
        <p className='milan-text-red lh-1 mt-1 mb-0'>
          <small>
            <small>{error}</small>{' '}
          </small>
        </p>
      )}
    </div>
  );
};

export default ApprovedAmountInput;
