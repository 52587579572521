import React from 'react';
import { Formik, Form } from 'formik';
import FormikText from './formik/FormikText';
import FormikTextArea from './formik/FormikTextArea';
import EmailInput from './formik/EmailInput';
import AttachmentsInputs from './formik/AttachmentsInputs';
import * as Yup from 'yup';
import axios from 'axios';
import useDates from '../../hooks/useDates';
// import { alphaeon28Verbiage, AlphaeonPrime } from '../../constants';

const initialValues = {
  subject: 'Your eQuote from Milan Laser',
  client_email: '',
  milan_email: 'info@milanlaser.com',
  cc: '',
  message: '',
  attachments: [],
};

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Required'),
  client_email: Yup.string().email('Invalid email').required('Required'),
  milan_email: Yup.string().email('Invalid email').required('Required'),
  cc: Yup.string().email('Invalid email'),
  message: Yup.string(),
  attachments: Yup.array(),
});

const attachments = [
  'Pre Post Care',
  'Brochure',
  'Infographic',
  'Refer a Friend',
  'PCOS Brochure',
];

function SendEquote({ options, setDisplayContent, active }) {
  // const isAlphaeon28 =
  //   active.lender.name === AlphaeonPrime.name && active.financePlan === 36;
  const dates = useDates(options, active);
  const onSubmit = (formValues, props) => {
    // axios.post(`http://localhost:5001/send_email`, {
    axios
      .post(`${process.env.REACT_APP_HEROKU_QUOTE_PATH}/send_email`, {
        // .post(`https://equote-previ-feature-39-ymrf2q.herokuapp.com/send_email`, {
        recipient_email: formValues.client_email.trim(),
        cc: formValues.cc.trim(),
        subject: formValues.subject,
        message: formValues.message,
        lender: undefined,
        secondaryLender: 'myMILAN',
        options: options,
        attachments: formValues.attachments,
        dates: {
          issued: dates.today,
          expires: dates.expires,
        },
        // noteUnderPrice: isAlphaeon28 ? alphaeon28Verbiage : undefined,
        noteUnderPrice: undefined,
      })
      .then((res) => {
        console.log(res);
        props.setStatus('Success');
        setTimeout(() => {
          props.resetForm();
        }, 20000);
      })
      .catch((err) => {
        props.setStatus('Failed');
        setTimeout(() => {
          props.resetForm();
        }, 20000);
        console.log(err);
      })
      .finally(props.setSubmitting(false));
  };

  const handleCancel = (formik) => {
    formik.resetForm();
    setDisplayContent(null);
  };
  return (
    <div className='row justify-content-center py-5 milan-bg-secondary'>
      <div className='col-12'>
        <h3 className='text-center mb-4'>Email Quote</h3>
      </div>

      <div className='col-lg-11 col-xl-10 col-xxl-9'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(formValues, props) => onSubmit(formValues, props)}
          validateOnChange={false}
        >
          {(formik) => {
            return (
              <Form id='send-equote' className='w-100 pb-4'>
                {formik.isSubmitting ? (
                  <span>Submitting...</span>
                ) : formik.status === 'Success' ? (
                  <div className='text-center'>
                    <p className='text-success display-6'>SUCCESS!</p>
                    <p className='milan-text-primary'>eQuote has been sent</p>
                    <button
                      type='button'
                      className='milan-btn milan-fallback-btn'
                      onClick={formik.resetForm}
                    >
                      Done
                    </button>
                  </div>
                ) : formik.status === 'Failed' ? (
                  <div>
                    <p className='milan-text-red fs-4'>Failed to send eQuote</p>
                    <button
                      type='button'
                      className='milan-btn milan-fallback-btn'
                      onClick={formik.resetForm}
                    >
                      Try Again
                    </button>
                  </div>
                ) : (
                  <>
                    <div className='row form-inputs-wrapper'>
                      <div className='col-md-5'>
                        <EmailInput
                          name='client_email'
                          label='Client Email'
                          placeholder='clientemail@mailbox.com'
                        />
                        <EmailInput
                          name='milan_email'
                          label='Milan Email'
                          disabled
                        />
                        <EmailInput
                          name='cc'
                          label='Send Email From'
                          optional
                          placeholder='Your Email'
                        />
                      </div>
                      <div className='col-md-7'>
                        <FormikText
                          name='subject'
                          label='Subject'
                          placeholder='Your eQuote from Milan Laser'
                          type='text'
                        />
                        <FormikTextArea
                          name='message'
                          label='Message'
                          optional
                          placeholder='Hello...'
                          rows='1'
                        />
                        <AttachmentsInputs
                          attachments={attachments}
                          name='attachments'
                          formik={formik}
                        />
                      </div>
                    </div>
                    <div className='row flex-sm-row-reverse gy-3 justify-content-center mt-1 mt-lg-3'>
                      <div className='col-12 col-sm-auto text-center'>
                        <button
                          type='submit'
                          className='milan-btn milan-red-btn'
                          style={{ width: '330px' }}
                          disabled={!formik.isValid}
                        >
                          Send Email
                        </button>
                      </div>

                      <div className='col-12 col-sm-auto text-center'>
                        <button
                          className='milan-btn milan-navy-btn'
                          style={{ width: '330px' }}
                          type='button'
                          onClick={() => handleCancel(formik)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default SendEquote;
