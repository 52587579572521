type LenderProp = {
  name: string;
  rate: {
    min: number;
    max: number;
  };
  downpayment: number;
  minLoanRequired: {
    // min loan required for each term by lender
    [key: number]: number;
  };
  financePlan: number[];
  canSplit: boolean;
  display: boolean;
};

// type LenderInput = Omit<LenderProp, 'financePlan'>;

// export const createLender = ({
//   name,
//   rate,
//   downpayment,
//   minLoanRequired,
//   canSplit = true,
// }: LenderInput): LenderProp => ({
//   name,
//   rate,
//   downpayment,
//   financePlan: Object.keys(minLoanRequired).map(Number),
//   minLoanRequired: { ...minLoanRequired },
//   canSplit,
// });

export const updateLenderRate = (
  lender: LenderProp,
  rate: { min: number; max: number }
): LenderProp => {
  let lenderCopy = { ...lender };
  lenderCopy.rate = rate;
  return lenderCopy;
};
