import { calculateAPRMonthlyPayment } from './calculateAPRMonthlyPayment';

/**
 * The Monthly Payment for all lenders except myMILAN
 * @param {number} cost
 * @param {number} downpayment
 * @param {number} minRate minimum interest rate
 * @param {number} maxRate maximum interest rate
 * @param {number} term 24, 36, 60 months
 * @returns {array}
 */
export const handleMonthlyPmt = (cost, downpayment, minRate, maxRate, term) => {
  // $$ IMPORTANT: to use calculateAPRMonthlyPayment, the interest rate must be > 0, otherwise monthlyPayment will be NaN
  let monthlyPayment =
    minRate > 0 || maxRate > 0
      ? [
          calculateAPRMonthlyPayment(cost - downpayment, minRate, term),
          calculateAPRMonthlyPayment(cost - downpayment, maxRate, term),
        ]
      : [(cost - downpayment) / term];
  return monthlyPayment;
};
