import React from 'react';

export function Disclaimer({ dates, textColor }) {
  return (
    <div className='row'>
      <div className='col'>
        <p className={`text-center mb-0 milan-text-${textColor}`}>
          <small>
            {/* All quotes are approximate monthly price.{' '}
            <br className='d-md-none' />
            <span className='d-none d-md-inline'>|</span>  */}
            Quote issued on {dates.today} <br className='d-sm-none' />
            <span className='d-none d-sm-inline'>|</span> {dates.expires}
          </small>
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
