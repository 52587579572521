import React from 'react';
import OptionWrapper from './OptionWrapper';

export function OptionsContainer({ options, splitFinanceFeature }) {
  return (
    <section className='container-fluid py-4'>
      <div className='row gy-5'>
        {Object.keys(options).map((key) => (
          <OptionWrapper
            key={key}
            option={options[key]}
            optionKey={key}
            splitFinanceFeature={splitFinanceFeature}
          />
        ))}
      </div>
    </section>
  );
}

export default OptionsContainer;
