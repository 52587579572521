import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from './TextError'

export function EmailInput(props) {
  const { label, name, optional, ...rest } = props
  return (
    <div className="mb-3">
      <label className="mb-1">{label} {optional ? <small>{`(Optional)`}</small> : ''}</label>
      <Field name={name} >
        {
          props => {
            const { field, meta } = props
            return (
              <input
                className={`form-control ${meta.error && meta.touched ? 'border-error' : ''} ${rest.disabled ? 'text-muted' : ''}`}
                type="email"
                id={name}
                {...field} {...rest} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default EmailInput