import React from 'react';
import Card from 'react-bootstrap/Card';
import { USCurrentStyleDecimal } from '../../utils';
// import { alphaeon28Verbiage, AlphaeonPrime } from '../../constants';

function OptionOutputsTop({ option, activeLender }) {
  // const isAlphaeon28 =
  //   activeLender === AlphaeonPrime.name && option.financePlan === 28;
  return (
    <Card.Body className='py-1 px-0'>
      {option.monthlyPayment[0] > 0 &&
      option.monthlyPayment[1] > 0 &&
      option.monthlyPayment[0] !== option.monthlyPayment[1] ? (
        <h3 className='text-center display-6 milan-text-navy mb-0'>
          {USCurrentStyleDecimal(option.monthlyPayment[0])}
          <span className='mx-xl-1'>-</span>
          {USCurrentStyleDecimal(option.monthlyPayment[1])}
        </h3>
      ) : (
        <h3 className='text-center display-6 milan-text-navy mb-0'>
          {USCurrentStyleDecimal(option.monthlyPayment[0])}
        </h3>
      )}
      {option.splitLoan.isEnabled ? (
        <p className='milan-text-navy text-center mb-1'>
          <small>Per month</small>
        </p>
      ) : (
        <p className='milan-text-navy text-center mb-1'>
          <small>
            Per month for <strong>{option.financePlan} months</strong>
          </small>
        </p>
      )}
      {/* {!option.splitLoan.isEnabled && isAlphaeon28 && (
        <p className='lh-sm fine-print mb-2 mx-4 milan-text-tur fst-italic'>
          {alphaeon28Verbiage}
        </p>
      )} */}
    </Card.Body>
  );
}

export default OptionOutputsTop;
