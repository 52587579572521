/**
 * This function updates the selections in the AreasContainer upon switching between Options or adding/removing an area in the ACTIVE Option
 * @param {array} parentsAreasArr
 * @param {array} activeOptionItems
 */

export const updateSelections = (parentsAreasArr, activeOptionItems) => {
  // 1. Unselect all items initially
  parentsAreasArr.forEach((parentArea) =>
    parentArea.areas.forEach((area) => (area.selected = false))
  );
  // 2. Loop through the items in the active option array
  activeOptionItems.forEach((item) => {
    // 3. Find the index of the corresponding parent area in the PARENTS_AREAS_ARR array
    const parentAreaIndex = parentsAreasArr.findIndex(
      (parentArea) => parentArea.id === item.parentAreaId
    );
    // 4. Find the child area as well
    const foundArea = parentsAreasArr[parentAreaIndex].areas.find(
      (area) => area.id === item.id
    );
    // 5. Set "selected" to be true
    foundArea.selected = true;
  });
};
