import React, { useState, useRef } from 'react';
import ForManagers from './for-managers/ForManagers';
import SendEquote from './SendEquote';
import ForCC from './for-cc/ForCC';
import Disclaimer from '../Disclaimer';
import ReactToPrint from 'react-to-print';

export function QuoteActionsContainer({ options, printRef, active, dates }) {
  const [displayContent, setDisplayContent] = useState(null);
  const containerRef = useRef(null);
  const handleClick = (action) => {
    setDisplayContent(action);
    setTimeout(() => {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 150);
  };
  return (
    <section id='quote-actions' className='mt-4 px-0'>
      <div className='container-fluid milan-bg-navy py-5'>
        <Disclaimer dates={dates} textColor='white' />
        {Object.keys(options).some((key) => options[key].items.length > 0) && (
          <div className='row gy-3 justify-content-center pt-4'>
            <div className='col-6 col-md-auto'>
              <ReactToPrint
                trigger={() => (
                  <button
                    type='button'
                    className={`milan-btn quote-action-btn w-100 ${
                      active.preSale ? 'primary-bg' : ''
                    }`}
                  >
                    Print Quote
                  </button>
                )}
                content={() => printRef.current}
                onBeforePrint={() => setDisplayContent('Print')}
              />
            </div>
            <div className='col-6 col-md-auto'>
              <button
                type='button'
                className={`milan-btn quote-action-btn ${
                  active.preSale ? 'primary-bg' : ''
                } w-100 ${displayContent === 'EQuote' ? 'active' : ''}`}
                onClick={() => handleClick('EQuote')}
              >
                Email Quote
              </button>
            </div>
            <div className='col-6 col-md-auto'>
              <button
                type='button'
                className={`milan-btn quote-action-btn ${
                  active.preSale ? 'primary-bg' : ''
                } w-100 ${displayContent === 'Managers' ? 'active' : ''}`}
                onClick={() => handleClick('Managers')}
              >
                For Managers
              </button>
            </div>
            <div className='col-6 col-md-auto'>
              <button
                type='button'
                className={`milan-btn quote-action-btn ${
                  active.preSale ? 'primary-bg' : ''
                } w-100 ${displayContent === 'CC' ? 'active' : ''}`}
                onClick={() => handleClick('CC')}
              >
                For CC'S
              </button>
            </div>
          </div>
        )}
      </div>
      {Object.keys(options).some((key) => options[key].items.length > 0) && (
        <div ref={containerRef} className='container-fluid'>
          {displayContent === 'Managers' ? (
            <ForManagers options={options} />
          ) : displayContent === 'EQuote' ? (
            <SendEquote {...{ options, setDisplayContent, active }} />
          ) : displayContent === 'CC' ? (
            <ForCC {...{ options, active }} />
          ) : null}
          {(displayContent === 'Managers' || displayContent === 'CC') && (
            <div className='row pb-4'>
              <div className='col'>
                <button
                  className='milan-btn milan-primary-btn clear-option-btn'
                  onClick={() => setDisplayContent('')}
                >
                  Close This Area
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default QuoteActionsContainer;
