import React from 'react'
import CCContent from './CCContent'

function ForCC({ options, active }) {
  return (
    <div className="row gy-3 flex-wrap pt-5 py-4">
      <div className="col">
        <div className={`card pt-3 px-3 card-border ${active.preSale ? 'primary-border' : ''}`}>
          {
            Object.keys(options).map(key => (
              <CCContent key={key} optionKey={key} option={options[key]}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ForCC