// For each Option Tile, the initial values are set here
export const initialOptionValues = {
  financePlan: 24, // Finance Plan could vary between Options based on each option's items quantity
  monthlyPayment: [],
  totalOriginalCost: 0,
  finalTotalCost: 0,
  totalSavings: 0,
  privateSale: 0,
  downPmtEnabled: false, // Down Payment Check Box
  downpayment: 0, // The amount of down payment entered manually
  requiredDownPmt: 0, // The amount of down payment required by lender
  todaysPayment: 0, // This could be down payment entered manually OR required amount by lender
  additionalDownPmt: 0, // Down Payment entered manually - Required Down Payment
  totalFinanced: 0,
  fee: 0,
  pif: 0, // Pay in full
  items: [],
  splitLoan: {
    // Spit Financing Logic
    // Splitting loan between 2 lenders
    isEnabled: false,
    primaryLender: {
      approvedAmount: 0,
      financedAmount: 0,
      requiredDownPmt: 0, // Down payment required by primary lender
      monthlyPayment: [],
    },
    secondaryLender: {
      financedAmount: 0,
      requiredDownPmt: 0, // Down payment required by secondary lender
      monthlyPayment: [],
    },
  },
};
