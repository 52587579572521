import React from 'react'

function ClearOptionBtn({option, clearOption, optionKey}) {
  return (
    <div className="text-center mt-4 print-hide">
    <button
      type="button"
      className="milan-btn milan-primary-btn clear-option-btn"
      onClick={() => clearOption(optionKey)}
      disabled={option.items.length === 0}>
      Clear Option {optionKey}
    </button>
  </div>
  )
}

export default ClearOptionBtn