export const getLowestPriceItem = (option, cohort) => {
  // Clone option.items so it doesn't mutate when sorting
  let optionItemsClone = [...option.items];

  // For New Client, all items get 60% discount EXCEPT the lowest item price gets 50% discount
  // For New Client, Find the lowest price of an option's items
  let lowestPriceItem = {};
  // Consider a bundle area as the lowest price if it exists // Because Milan Money doesn't apply to bundles
  const bundleItem = optionItemsClone.find(
    (item) => item.parentAreaId === 'bundles'
  );
  // Sort option items array, so the lowest price will be the first item
  lowestPriceItem =
    bundleItem ||
    optionItemsClone.sort(
      (a, b) => a.prices[`cohort_${cohort}`] - b.prices[`cohort_${cohort}`]
    )[0]; // returns an object

  return lowestPriceItem;
};
