// BYOB Discount Logic
export const isBYOBApplicable = (area) =>
  area.parentAreaId !== 'bundles' && !area.parentAreaId.includes('upgrades');

export const isBYOBItem = (item) =>
  isBYOBApplicable(item) && item.discount === 'BYOB';

export const getBYOBItems = (items) => items.filter((item) => isBYOBItem(item));
export const getBYOBApplicableItems = (items) =>
  items.filter((item) => isBYOBApplicable(item));
