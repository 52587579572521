// -------------- CA EVENT Logic ----------------- //
import { isDateInRange } from '../utils/isDateInRange';
import {
  eventStartDate as startDate,
  disclaimerEndDate as endDate,
} from '../constants/eventDates';
// ------------------------------------------------//

const splitWithComma = (date) => `${date.slice(0, 10)}, ${date.slice(-4)}`;

export function useDates(options, active) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  let today = currentDate.toDateString();
  const currentMonth = months[currentDate.getMonth()];
  // const nextMonth = months[currentDate.getMonth() + 1];

  let nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  nextDay = nextDay.toDateString();

  const optionsClone = { ...options };

  // For Upgrades Items, the default discount is Upgrade but it's basically CSS behind the scene. However, the CSS logic doesn't apply when it comes to expDate
  let someCSS = Object.keys(optionsClone).some((key) =>
    optionsClone[key].items.some(
      (item) =>
        !item.parentAreaId.includes('upgrades') && item.discount === 'CSS'
    )
  );
  // let isEventDiscount = Object.keys(optionsClone).some(key => (optionsClone[key].items.some(item => item.discount === 'Event')))

  // let expires = someCSS ? splitWithComma(nextDay) : splitWithComma(lastDay) // Default
  // let expires = someCSS ? splitWithComma(nextDay) : isEventDiscount ? 'Tue Apr 30, 2024' : splitWithComma(lastDay)

  // -------------- CA EVENT Logic -------------- //
  const isCAEvent =
    active.discount === 'CA EVENT' || active.discount === 'CA EVENT - NEW';
  const inRange = isDateInRange(startDate, endDate);
  const formattedEndDate = splitWithComma(endDate.toDateString());
  // ------------------------------------------------//

  let expires = someCSS
    ? `Quote valid until ${splitWithComma(nextDay)}`
    : inRange && isCAEvent // For CA EVENT Logic
    ? `This quote is good through ${formattedEndDate}, for the price of laser hair removal.  Financing options subject to change.` // CA EVENT Logic
    : `This quote is good through the end of ${currentMonth} for the price of laser hair removal. Financing options subject to change.`;

  // let expires = someCSS ? splitWithComma(nextDay) : 'Tue Apr 30, 2024'

  return {
    today: splitWithComma(today),
    expires,
  };
}
export default useDates;

// let lastDay = new Date(
//   currentDate.getFullYear(),
//   currentDate.getMonth() + 1,
//   0
// ).toDateString();
