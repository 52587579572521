// Calculate myMILAN monthly Payment (Default Formula) // NO additional down payment
/**
 * Calculate installment for a Milan lender when there's no additional down payment
 * @param {number} remainingBalanceAmount Milan Lender Financed Amount
 * @param {object} myMilanLender Milan Lender Object that's specific to the active state
 * @returns {number}
 */
export const calculateInstallment = (remainingBalanceAmount, myMilanLender) => {
  const n = myMilanLender.financePlan[0]; // 24 months
  const apr = myMilanLender.rate.min; // myMILAN min rate is as same as max rate
  let newInstallment = (
    (remainingBalanceAmount - 0) /
    ((1 - 1 / Math.pow(1 + apr / 12, n - 1)) / (apr / 12) + 1)
  ).toFixed(2);
  return +newInstallment;
};

// NOTE: Additional Down Payment means: The Down Payment Input is enabled and it has a value larger than the required down payment.

/**
 * Calculate installment for a Milan lender when there is additional down payment
 * @param {number} financed Milan Lender Financed Amount
 * @param {number} remainingDown Remaining Down Payment (Today's Payment - Primary Lender Required Down Payment)
 * @param {object} myMilanLender Milan Lender Object that's specific to the active state
 * @returns {number}
 */

// Another formula to calculate myMILAN monthly Payment (with additional down payment)
export const calcInstallmentWithDown = (
  financed,
  remainingDown,
  myMilanLender
) => {
  const term = myMilanLender.financePlan[0]; // 24 months
  const apr = myMilanLender.rate.min; // myMILAN min rate is as same as max rate
  return +(
    ((financed - remainingDown) * (apr / 12)) /
    (1 - Math.pow(1 + apr / 12, 1 - term))
  ).toFixed(2);
};
