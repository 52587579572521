// When the Down Payment input is enabled and amount entered is larger than the requiredDownPmt
/**
 * This function splits the total down payment entered by user into required & additional down payments. It also calculates the total financed
 * @param {number} cost final total cost
 * @param {number} totalDownPmt entered down payment amount
 * @param {number} downPmtPercentage lender minimum required percentage
 * @returns {object} additionalDownPmt, requiredDownPmt, totalFinanced
 */
export const calcRequiredDownPmt = (cost, totalDownPmt, downPmtPercentage) => {
  const additionalDownPmt =
    (totalDownPmt - cost * downPmtPercentage) / (1 - downPmtPercentage);
  const requiredDownPmt = totalDownPmt - additionalDownPmt;
  return { additionalDownPmt, requiredDownPmt };
};
