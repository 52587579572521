import React from 'react';
import ApprovedAmountInput from './ApprovedAmountInput';
import { USCurrentStyleDecimal } from '../../../utils';
// import { alphaeon28Verbiage, AlphaeonPrime } from '../../../constants';

function OptionSplitLoan({ option, active, setOptions }) {
  const optionRequiredDownPmt = option.requiredDownPmt;
  const primaryRequiredDownPmt = option.splitLoan.primaryLender.requiredDownPmt;
  const secondaryRequiredDownPmt =
    option.splitLoan.secondaryLender.requiredDownPmt;

  // const isAlphaeon28 =
  //   active.lender.name === AlphaeonPrime.name && option.financePlan === 28;
  return (
    <>
      <ApprovedAmountInput {...{ option, active, setOptions }} />
      {option.splitLoan.primaryLender.approvedAmount > 0 && (
        <>
          <div className='row'>
            <div className='col-12'>
              <strong>
                {active.lender.name} (per {option.financePlan} months
              </strong>
              )
              <div>
                {USCurrentStyleDecimal(
                  option.splitLoan.primaryLender.financedAmount
                )}{' '}
                |
                {USCurrentStyleDecimal(
                  option.splitLoan.primaryLender.monthlyPayment[0]
                )}
                {option.splitLoan.primaryLender.monthlyPayment[1] &&
                  option.splitLoan.primaryLender.monthlyPayment[0] !==
                    option.splitLoan.primaryLender.monthlyPayment[1] && (
                    <>
                      <span> - </span>{' '}
                      {USCurrentStyleDecimal(
                        option.splitLoan.primaryLender.monthlyPayment[1]
                      )}
                    </>
                  )}
                <small>
                  <small>/Mo.</small>
                </small>
              </div>
              {primaryRequiredDownPmt > 0 &&
                active.lender.downpayment > 0 &&
                optionRequiredDownPmt > primaryRequiredDownPmt && (
                  <p className='milan-text-primary fine-print mb-0'>{`(${USCurrentStyleDecimal(
                    primaryRequiredDownPmt
                  )} required down payment)`}</p>
                )}
              {/* {isAlphaeon28 && (
                <p className='lh-sm fine-print mb-0 mx-3 milan-text-tur fst-italic'>
                  {alphaeon28Verbiage}
                </p>
              )} */}
            </div>
          </div>
          <div className='row mt-2 pb-3'>
            <div
              className={`col-12  ${
                option.splitLoan.secondaryLender.financedAmount <= 0
                  ? 'milan-text-red'
                  : ''
              }`}
            >
              <strong>myMILAN (per 24 months</strong>)
              <div>
                {USCurrentStyleDecimal(
                  option.splitLoan.secondaryLender.financedAmount
                )}{' '}
                |
                {USCurrentStyleDecimal(
                  option.splitLoan.secondaryLender.monthlyPayment[0]
                )}
                <small>
                  <small>/Mo.</small>
                </small>
              </div>
              {secondaryRequiredDownPmt > 0 &&
                optionRequiredDownPmt > secondaryRequiredDownPmt && (
                  <p className='milan-text-primary fine-print mb-0'>{`(${USCurrentStyleDecimal(
                    secondaryRequiredDownPmt
                  )} required down payment)`}</p>
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default OptionSplitLoan;
