/**
 * This function returns the interest rate range {minRate, maxRate}
 * @param {object} active
 * @param {boolean} isSynchrony60
 * @returns {object}
 */

export const getInterestRate = (active, isSynchrony60) => {
  const minRate = isSynchrony60 ? 0.0499 : active.lender.rate.min;
  const maxRate = isSynchrony60 ? 0.0999 : active.lender.rate.max;
  return { minRate, maxRate };
};
