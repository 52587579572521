import { handleMonthlyPmt } from '../handleMonthlyPmt';

// -------------------  $$$$ Split Financing $$$$ ------------------- //
// ----------------- $$$$ Primary Lender Logic $$$$ ------------------- //
export const handlePrimaryLender = (
  active,
  option,
  primaryLender,
  minRate,
  maxRate
) => {
  // Primary Lender is any lender except myMILAN
  // When Primary Lender requires down payment as a PERCENTAGE (Covered Care for example)
  // Some lenders require down payment (e.g. 5% required down payment for Covered Care)
  primaryLender.requiredDownPmt =
    active.lender.downpayment * primaryLender.approvedAmount;

  primaryLender.financedAmount =
    primaryLender.approvedAmount - primaryLender.requiredDownPmt;

  primaryLender.monthlyPayment = handleMonthlyPmt(
    primaryLender.approvedAmount,
    primaryLender.requiredDownPmt,
    minRate,
    maxRate,
    option.financePlan
  );
};
