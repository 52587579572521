export const processUpgradeArea = (area, bodyHalf) => {
  // Note: upgrade_from can be more than one area (Lip + Chin as an example)
  // Note: upgrade_from is an array, however upgrade_to is a string
  let upgradeFromAreas = area.upgrade_from.map((areaId) =>
    bodyHalf.find((item) => item.id === areaId)
  ); // upgrade_from is an array
  let upgradeFromPrice = (x) =>
    upgradeFromAreas.reduce((acc, obj) => acc + obj.prices[`cohort_${x}`], 0);
  let upgradeToPrice = (x) =>
    bodyHalf.find((item) => item.id === area.upgrade_to).prices[`cohort_${x}`]; // upgrade_to is a string
  area.prices = {
    cohort_1: upgradeToPrice(1) - upgradeFromPrice(1),
    cohort_2: upgradeToPrice(2) - upgradeFromPrice(2),
    cohort_3: upgradeToPrice(3) - upgradeFromPrice(3),
  };
};
