import React from 'react';

const CustomCheckbox = ({ id, label, handleCheckBox, isChecked }) => {
  return (
    <div className={`checkbox-container`} onClick={() => handleCheckBox(id)}>
      <div className="checkbox-wrapper">
        <div className={`checkbox ${isChecked ? 'checked' : ''} me-2`}>{isChecked && <span>✔</span>}</div>
        <div className="label">{label}</div>
      </div>
    </div>
  );
};

export default CustomCheckbox;
