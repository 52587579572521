export const FEES = [
  {
    id: 'no_fees',
    name: 'No Fees',
    percentage: 0,
  },
  {
    id: 'denver_east',
    name: 'Denver East (Aurora)',
    percentage: 0.005,
  },
  {
    id: 'denver_south',
    name: 'Denver South (Sheridan)',
    percentage: 0.01,
  },
  {
    id: 'coloradosprings',
    name: 'Colorado Springs',
    percentage: 0.0075,
  },
];
