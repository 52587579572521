import React, { useContext } from 'react';
import { QuoteContext } from '..';
import { genderNeutral } from '../../utils';
import { SelectLender, STATES } from '../../constants';
import { processUpgradeArea } from './processUpgradeArea';
import {
  isBYOBItem,
  isBYOBApplicable,
  getBYOBItems,
  getBYOBApplicableItems,
} from '../../utils/byob';

function AreasList({ parentArea, bodyHalf }) {
  const { options, setOptions, active, setActive, clearOption } =
    useContext(QuoteContext);
  const isUpgrade = parentArea.name.includes('Upgrade');
  const x = active.optionKey;

  const handleClick = (area) => {
    // ----  If lender or state are not selected yet, return ---- //
    if (
      active.lender.name === SelectLender.name ||
      active.state.name === STATES[0].name
    )
      return;
    // Important: Notice here we're only updating the active option.items array
    // Setting the body area to be (selected or unselected) doesn't actually happen here

    // ------ If the area is selected ------ //
    if (area.selected && area.selected === true) {
      let activeOptionItems = options[x].items;
      // If the last item is deleted -> clear the option
      if (activeOptionItems.length === 1) {
        clearOption(x);
      } else {
        // ------ BYOB Discount Logic ------ //
        const item = activeOptionItems.find((item) => item.id === area.id);
        if (active.discount === 'BYOB' && isBYOBItem(item)) {
          const byobItems = getBYOBItems(activeOptionItems);
          // At this point, the area hasn't been removed yet
          if (byobItems.length === 3) {
            activeOptionItems.forEach((item) => {
              if (isBYOBItem(item)) item.discount = '50%';
            });
          }
        }
        // ------ End - BYOB Discount Logic ------ //
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [...activeOptionItems.filter((item) => item.id !== area.id)],
          },
        }));
      }
    } else if (
      options[x].items.length > 0 &&
      options[x].items[0].id === 'full_body'
    )
      return; // Don't add more areas if a full body is selected
    else {
      // If the area is NOT selected
      const areaClone = { ...area }; // To avoid altering the original area object
      areaClone.name = genderNeutral(area.name);
      areaClone.parentAreaId = parentArea.id; // Parent Area ID: this is useful in other components
      // Get area.prices programmatically for upgrade areas
      if (isUpgrade) processUpgradeArea(areaClone, bodyHalf);

      const isFullBodyArea = areaClone.id === 'full_body';

      let activeOptionItems = [...options[x].items, areaClone];
      // ------ BYOB Discount Logic ------ //
      if (active.discount === 'BYOB') {
        if (isBYOBApplicable(areaClone)) {
          const byobApplicableItems = getBYOBApplicableItems(activeOptionItems);
          if (byobApplicableItems.length >= 3) {
            activeOptionItems.forEach((item) => {
              if (isBYOBApplicable(item)) item.discount = active.discount;
            });
          } else {
            areaClone.discount = '50%';
          }
        } else {
          areaClone.discount =
            areaClone.parentAreaId === 'bundles' ? 'BNDLE' : '50%';
        }
      } // ------ End - BYOB Discount Logic ------ //
      else {
        // CSS will default to Upgrade for upgrade areas
        areaClone.discount =
          active.preSale && isUpgrade
            ? 'CSS'
            : //   : (parentArea.id === 'bundles') ? 'BNDLE'
            parentArea.id === 'bundles' &&
              active.discount !== 'Military' &&
              active.discount !== 'Event'
            ? 'BNDLE'
            : active.discount;
      }

      if (isFullBodyArea) {
        // If the active Option has items && it is NOT the last Option -> Move to the next Option
        // IMPORTANT: Do NOT use the activeOptionItems variable here
        if (options[x].items.length > 0 && x < Object.keys(options).length) {
          let activeKey = x + 1;
          setOptions((prev) => ({
            ...prev,
            [activeKey]: {
              ...options[activeKey],
              items: [areaClone],
            },
          }));
          setActive((prev) => ({ ...prev, optionKey: activeKey }));
        } else {
          // If the active Option does NOT have items OR it's the last Option, REPLACE current items
          setOptions((prev) => ({
            ...prev,
            [x]: {
              ...options[x],
              items: [areaClone],
            },
          }));
        }
      } else {
        setOptions((prev) => ({
          ...prev,
          [x]: {
            ...options[x],
            items: [...activeOptionItems],
          },
        }));
      }
    }
  };

  return (
    <ul id={parentArea.id} className='row flex-wrap padding-x g-3 my-4'>
      {parentArea.areas.map((area) => (
        <li key={area.id} className='col-auto'>
          <button
            type='button'
            className={`sub-area-item rounded px-3 ${
              active.preSale ? 'primary-bg' : ''
            }  ${area.selected && area.selected === true ? 'selected' : ''}`}
            onClick={() => handleClick(area)}
          >
            {area.name}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default AreasList;
