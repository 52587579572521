import { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';

export function useBodyAreas() {
  const [bodyAreas, setBodyAreas] = useState([]);

  const FIREBASE_COLLECTION = process.env.REACT_APP_FIREBASE_COLLECTION;
  const collectionRef = collection(db, FIREBASE_COLLECTION);
  const q = query(collectionRef, orderBy('index'));

  useEffect(() => {
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        let fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        fetchedData.forEach((doc) =>
          doc.areas.sort((a, b) => a.name.localeCompare(b.name))
        );
        fetchedData.forEach((doc) =>
          doc.areas.sort((a, b) => a.index - b.index)
        ); // if there's an index
        fetchedData.forEach((doc) => {
          const areas = [...doc.areas];
          doc.areas = areas.filter((area) => area.display);
        });
        setBodyAreas(fetchedData);
      },
      (error) => {
        console.error(error);
      }
    );
    // Stop listening to changes
    return unsubscribe;
  }, []);
  return bodyAreas;
}
export default useBodyAreas;

// import { doc, updateDoc } from "firebase/firestore"
// Outside the useffect
// const addNewPropToAreas = async (parent) => {
//   let areasArr = [...parent.areas]
//   areasArr.forEach(area => {
//     area.display = true
//   })
//   const docRef = doc(db, FIREBASE_COLLECTION, parent.id)
//   updateDoc(docRef, { areas: areasArr })
// }

// Inside the useEffect
// addNewPropToAreas(fetchedData[6])
