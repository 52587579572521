import React from 'react';
import { USCurrentStyleDecimal } from '../../../utils';

// For Call Center Only
function CCContent({ option, optionKey }) {
  if (!option.items.length) return null;
  const term = 36;
  return (
    <div className='mb-3'>
      <strong className='d-block mb-2'>Option {optionKey}</strong>
      <p className='mb-0'>
        {option.items.map((item, x) => (
          <span key={x}>
            {item.name}
            {x === option.items.length - 1
              ? ': '
              : x === option.items.length - 2
              ? ' & '
              : ', '}
          </span>
        ))}
        {USCurrentStyleDecimal(option.finalTotalCost / term)} per month for{' '}
        {term} months
      </p>
    </div>
  );
}

export default CCContent;
