import { Synchrony60 } from '../constants';
/**
 * If the active lender is Synchrony 60 and the final total cost is greater than $4000 return true
 * @param {object} active
 * @param {object} option
 * @returns {boolean}
 */
export const isValidSynchrony60 = (active, option) => {
  return (
    active.lender.name === Synchrony60.name && option.finalTotalCost > 4000
  );
};
