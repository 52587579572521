import { calcRequiredDownPmt, handleMonthlyPmt } from '.';
import { calculateInstallment, calcInstallmentWithDown } from './mmf';

/**
 * This function updates the option object when there is no split loan
 * @param {object} active
 * @param {object} option
 * @param {number} minRate
 * @param {number} maxRate
 * @param {number} accurateFinalTotalCost
 */

export const handleDownAndMonthlyPmt = (
  active,
  option,
  minRate,
  maxRate,
  accurateFinalTotalCost
) => {
  const isMyMilanPrimary = active.lender.name === 'myMILAN'; // As a primary lender.  NOT Secondary

  // 1. Handle down payment logic
  // ----  If lender allows down payment ----- //
  // --------  When lender requires minimum down payment as a PERCENTAGE (Covered Care for example) ---------- //
  // When the Down Payment input is enabled and amount entered is larger than the original requiredDownPmt
  if (
    active.lender.downpayment > 0 &&
    option.downPmtEnabled &&
    option.downpayment > option.requiredDownPmt
  ) {
    // In this case, we need to split the down payment into 2 parts: (required & additional)
    const { requiredDownPmt } = calcRequiredDownPmt(
      option.finalTotalCost,
      option.downpayment,
      active.lender.downpayment
    );
    option.requiredDownPmt = requiredDownPmt;

    // if the amount of down payment entered manually is smaller than the required one,
    // Today's Payment defaults to required amount
    option.todaysPayment =
      option.downpayment < option.requiredDownPmt
        ? option.requiredDownPmt
        : option.downpayment;

    // 2. Calculate the monthly payments
    option.monthlyPayment = handleMonthlyPmt(
      option.finalTotalCost,
      option.todaysPayment,
      minRate,
      maxRate,
      option.financePlan
    );
  }
  // When the Down Payment input is disabled
  else {
    option.requiredDownPmt = active.lender.downpayment * option.finalTotalCost;
    // if the amount of down payment entered manually is smaller than the required one,
    // Today's Payment defaults to required amount
    option.todaysPayment =
      option.downpayment < option.requiredDownPmt
        ? option.requiredDownPmt
        : option.downpayment;

    // 2. Calculate the monthly payments
    option.monthlyPayment = handleMonthlyPmt(
      option.finalTotalCost,
      option.todaysPayment,
      minRate,
      maxRate,
      option.financePlan
    );
  }

  // ------------ myMILAN Logic ------------ //
  // myMILAN is the active primary lender
  if (isMyMilanPrimary) {
    // myMILAN is the active primary lender
    // By default, myMILAN requires a down payment equal to the first month payment
    // Unlike other lenders, this required down payment (today's payment) must be included in the total financed amount
    // Therefore, this line will overwrite this line: (option.totalFinanced = option.finalTotalCost - option.todaysPayment) above
    option.totalFinanced = accurateFinalTotalCost; // Very important // Do NOT remove this line
    // DO NOT use: option.totalFinanced = option.finalTotalCost// It does NOT give accurate result

    // 1. Get the required down payment first
    // Required Down Payment is basically the monthly payment
    option.requiredDownPmt = calculateInstallment(
      option.totalFinanced,
      active.lender
    );

    // ---- In case an additional down payment is entered ---- //
    // Check if the entered down payment is smaller than the required one
    const isDownPmntSmallerThanRequired =
      option.downpayment <= option.requiredDownPmt;
    // The today's payment must be equal to or greater than the required down payment
    option.todaysPayment = isDownPmntSmallerThanRequired
      ? option.requiredDownPmt
      : option.downpayment;

    // 2. Use calcInstallmentWithDown even though there is no additional down payment
    // Treat the required down payment (today's payment in this case) as the additional down payment
    option.monthlyPayment = [
      calcInstallmentWithDown(
        accurateFinalTotalCost,
        option.todaysPayment,
        active.lender
      ),
    ];
    // Update Required Down Payment
    option.requiredDownPmt = option.monthlyPayment[0];
    // Update Today's Payment
    option.todaysPayment = isDownPmntSmallerThanRequired
      ? option.requiredDownPmt
      : option.downpayment;
    // Update Total Financed
    option.totalFinanced =
      accurateFinalTotalCost - option.todaysPayment + option.monthlyPayment[0];
  }
  // ------------ myMILAN Logic ------------ //
};
