import { FEES } from '.';
import { STATES } from './states.ts';
import { SelectLender } from './lenders.ts';

export const DEFAULT_ACTIVE = {
  optionKey: 1,
  discount: '50%',
  fee: FEES.find((fee) => fee.id === 'no_fees'),
  privateSale: 'None',
  financePlan: 36, //  36 OR 24
  lender: SelectLender,
  preSale: false,
  newClient: false,
  state: STATES[0],
};
