import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

export function FormikText(props) {
  const { label, name, optional, ...rest } = props
  return (
    <div className="mb-3">
      <label className="mb-1">{label}</label>
      <Field name={name}>
        {
          props => {
            const { field, meta } = props
            return (
              <input type="number" min={1} className={`form-control ${meta.error && meta.touched ? 'border-error' : ''}`} id={name} {...field} {...rest} aria-label={name} aria-describedby={name + '-addon'} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default FormikText