import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from './TextError'

export function AttachmentsInputs(props) {
  const { name, attachments, formik, ...rest } = props
  return (
    <div className="">
      <label>Include Attachments:</label>
      <ul className="row mt-2 g-2">
        <Field name={name} {...rest} >
          {
            ({ field }) => {
              return attachments.map((option, x) => {
                return (
                  <li className="col-sm-6 col-md-4 custom-checkbox" key={x}>
                    <label htmlFor={option} className="area_label">{option}
                      <input
                        type="checkbox"
                        {...field}
                        value={option}
                        id={option} />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                )
              })
            }
          }
        </Field>
      </ul>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}


export default AttachmentsInputs