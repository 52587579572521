import React, { useContext } from 'react';
import { QuoteContext } from '.';
import MILAN_LOGO_WHITE_WIDE from '../assets/Milan_Logo_White_Wide.svg';

export function QuoteController() {
  const { active, setActive, handleClearOptions } = useContext(QuoteContext);

  return (
    <header
      className={`${
        active.preSale ? 'milan-bg-primary' : 'milan-bg-tur'
      } py-4 padding-x`}
    >
      <nav className='container-fluid h-100 px-0'>
        <div className='row h-100 gy-4 justify-content-md-between align-items-center'>
          <div className='col-12 col-md-auto text-center'>
            <img
              className='logo'
              src={MILAN_LOGO_WHITE_WIDE}
              alt='Milan Laser Logo'
            />
          </div>
          <div className='col-12 col-md-auto'>
            <ul className='row gx-2 gx-sm-3 gy-3 justify-content-center justify-content-md-end mb-0'>
              <li className='col-auto'>
                <button
                  type='button'
                  disabled={!active.lender.financePlan.includes(36)}
                  onClick={() =>
                    setActive((prev) => ({ ...prev, financePlan: 36 }))
                  }
                  className={`toggle-btns rounded-start ${
                    active.financePlan === 36 ? 'active' : ''
                  }`}
                >
                  36 Month
                </button>
                <button
                  type='button'
                  disabled={!active.lender.financePlan.includes(24)}
                  onClick={() =>
                    setActive((prev) => ({ ...prev, financePlan: 24 }))
                  }
                  className={`toggle-btns rounded-end ${
                    active.financePlan === 24 ? 'active' : ''
                  }`}
                >
                  24 Month
                </button>
              </li>
              <li className='col-auto'>
                <button
                  type='button'
                  onClick={handleClearOptions}
                  className='rounded px-2 milan-text-navy milan-bg-white'
                >
                  <span className='d-md-none'>Reset</span>{' '}
                  <span className='d-none d-md-inline'>Clear Options</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default QuoteController;
